// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commissions-js": () => import("./../../../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-starie-privacy-js": () => import("./../../../src/pages/starie/privacy.js" /* webpackChunkName: "component---src-pages-starie-privacy-js" */),
  "component---src-pages-starie-terms-and-conditions-js": () => import("./../../../src/pages/starie/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-starie-terms-and-conditions-js" */)
}

